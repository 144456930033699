<template>
	<!-- <div v-if="checkTask != '' || formType == 'edit' || formType == 'detail'"> -->
	<div>
		<base-header class="pb-6">
	      <div class="row align-items-center py-4">
	        <div class="col-lg-6 col-7">
	          <h6 class="h2 text-white d-inline-block mb-0"></h6>
	        </div>
	      </div>
	    </base-header>
	    <div class="container-fluid mt--6">
	      <div class="card mb-4">
	        <div class="card-header mt--4">
	          <div class="row">
	            <div class="col">
	              <h3 class="mb-0 text-center" v-if="formType == 'edit' || formType == 'detail'">{{ tt("mcr_form") }}</h3>
	              <h3 class="mb-0 text-center" v-if="formType == 'approval'">{{ tt("mcr_approval") }}</h3>
	            </div>
	          </div>
	        </div>
	        <div class="card-body">
	          <McrHeader></McrHeader>
	          <br>
	          <McrItem></McrItem>
	        </div>
	        <div class="card-footer">                           
	            <span class="float-right">{{ totalItem }} Item</span>
	        </div>
	      </div>
	      <div class="row">

	      	<!-- SEND MCR -->
	        <div v-if="formType == 'edit'" class="col text-center">
	            <router-link :to="'/material/draft-list'" class="mr-2"><base-button size="sm" type="dark">Draft List</base-button></router-link>  
	            <base-button size="sm" type="primary" v-on:click="sendMcr(mcr_code)" :disabled="btnSend.onLoading || totalItem == 0">
	              <span v-if="btnSend.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
	              <span v-else>{{ tt('send') }}</span>
	            </base-button>
			</div>

	        <!-- MCR APPROVAL -->
			<div v-if="formType == 'approval'" class="col text-center">
	        	<base-button size="sm" type="warning" @click="insertNote('Revise')" v-if="authUserPermission['mcr-approval-revise']" :disabled="checkItem.disabled">
	        		<i class="ni ni-ruler-pencil"></i>{{ tt('revise') }}
	        	</base-button>
                <base-button size="sm" type="danger" @click="insertNote('Reject')" v-if="authUserPermission['mcr-approval-reject']">
                	<i class="ni ni-fat-remove"></i>{{ tt('reject') }}
                </base-button>
                <base-button size="sm" type="success" @click="insertNote('Approve')" v-if="authUserPermission['mcr-approval-approve']">
                	<i class="ni ni-check-bold"></i>{{ tt('approve') }}
                </base-button>
                <base-button size="sm" type="primary" @click="insertNote('Resend')" v-if="authUserPermission['mcr-approval-resend'] && checkStatusWorkflow == 'Revised'">
                	<i class="ni ni-send"></i>{{ tt('resend') }}
                </base-button>
	        </div>
	        
	      </div>
	    </div>

	    <!-- NOTE MODAL -->
	    <validation-observer v-slot="{invalid}">
		    <modal :show.sync="noteModal.show">
	            <template slot="header">
	                <h5 class="modal-title">{{ noteModal.title }}</h5>
	            </template>
	            <label class="form-control-label">Note <span class="text-danger" v-if="noteModal.mandatory == 'required'">*</span></label>
	            
	            <base-input :name="tt('note')" :rules="noteModal.mandatory">
	            	<el-input
		              type="textarea"
		              :rows="4"
		              v-model="noteModal.text">
		            </el-input>
	            </base-input>

	            <template slot="footer">
	                <base-button type="secondary" @click="noteModal.show = false">{{ tt('close') }}</base-button>
	                <base-button type="primary" v-on:click="approval()" :disabled="btnApproval.onLoading || invalid">
	                	<span v-if="btnApproval.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt('please_wait') }}</span>
	                    <span v-else>{{ noteModal.button }}</span>
	                </base-button>
	            </template>
	        </modal>
	    </validation-observer>
	</div>
	<!-- <expiredpage v-else/> -->
</template>

<script>
	import {mapState} from 'vuex'
	import Api from "@/helpers/api";
	import draftList from "@/services/material/draftList.service";
	import mcrApproval from '@/services/dashboard/mcrApproval.service';
	import McrHeader from "./McrHeader.vue";
	import McrItem from "./McrItem.vue";
	import myTaskDashboard from '@/services/dashboard/myTaskDashboard.service';

	export default {
		components: {
	      McrHeader,
	      McrItem
	    },
	    data() {
	    	return {
	    		btnApproval: {
	    			onLoading: false
	    		},
	    		btnSend: {
	    			onLoading: false
	    		},
                noteModal: {
                	show: false,
                	title: '',
                	text: '',
                	mandatory: '',
                	button: ''
                },
                checkItem: {
                	disabled: true,
                },
                urlApproval: '',
                checkTask: '',
                checkStatusWorkflow: '',
                params: this.$route.params,
	    		totalItem: 0,
	    		mcr_code: this.$route.params.mcr_code,
				token: this.$route.params.token,
				formType: this.$route.params.type
	    	}
	    },
	    computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
	    mounted() {
			this.get();
			if (this.formType == 'approval') {
				this.checkTaskTicket();
				this.statusWorkflow();
			}
		},
		methods: {
			get() {
				let context = this;

				Api(context, draftList.getTotalMcrItem(context.mcr_code, context.token))
		        .onSuccess(function(response) {
					context.totalItem = response.data.data;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.totalItem = 0;
		          }
		        })
		        .call();	

		        // Check Revised Item
				Api(context, draftList.checkRevisedItem(context.mcr_code))
		        .onSuccess(function(response) {
					if (response.data.data.length > 0) {
						context.checkItem.disabled = false;
					}
		        })
		        .call();	        
		    },
		    sendMcr(mcr_code) {
				this.btnSend.onLoading = true;

				let context = this;

				Api(context, draftList.sendMcr(mcr_code)).onSuccess(function(response) {
				  context.$notify({
				    message: response.data.message,
				    type: "success",
				    verticalAlign: 'bottom', 
					horizontalAlign: 'left'
				  });

				  context.$router.push('/material/draft-list');
				})
				.onFinish(function() {
				  context.btnSend.onLoading = false;
				})
				.call();
		    },
		    insertNote(type) {
		    	let context = this;

		    	context.noteModal.button = type;
		    	context.noteModal.mandatory = '';
		    	context.noteModal.show = true;
		    	context.noteModal.text = '';

		    	switch(type) {
		    		case 'Revise':
		    			context.noteModal.title = 'Revise MCR';
		    			context.noteModal.mandatory = 'required';
		    			context.urlApproval = 'revise'
		    			break;
		    		case 'Reject':
		    			context.noteModal.title = 'Reject MCR';
		    			context.noteModal.mandatory = 'required';
		    			context.urlApproval = 'reject'
		    			break;
		    		case 'Approve':
		    			context.noteModal.title = 'Approve MCR';
		    			context.urlApproval = 'approve'
		    			break;
		    		case 'Resend':
		    			context.noteModal.title = 'Resend MCR';
		    			context.urlApproval = 'reapprove'
		    			break;
		    	}
		    },
		    approval() {
		    	let context = this;
		    	context.btnApproval.onLoading = true;
				
				Api(context, mcrApproval.approval(context.urlApproval, context.mcr_code, {note : context.noteModal.text})).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });

                    context.noteModal.show = false;
                    context.noteModal.text = '';
                }).onFinish(function() {
                    context.btnApproval.onLoading = false;
                    context.$router.push('/dashboard/my-task-show/MCRAPPROVAL');
                })
                .call();
		    },
		    checkTaskTicket() {
                let context = this;
                Api(context, myTaskDashboard.check_task_ticket(this.mcr_code)).onSuccess(function(response) {
                    context.checkTask = response.data;
                }).onError(function(error) {
                    context.checkTask = [];
                }).call()
            },
            statusWorkflow() {
            	let context = this;
                Api(context, mcrApproval.statusWorkflow(this.mcr_code)).onSuccess(function(response) {
                    context.checkStatusWorkflow = response.data;
                }).onError(function(error) {
                    context.checkStatusWorkflow = '';
                }).call()
            }
		}
	};	
</script>